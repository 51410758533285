.App {
  background-color: #282c34;
  min-height: 100vh;
  color: white;
  padding: 40px;
}

.App-header {
  text-align: center;
  margin-bottom: 30px;
  font-size: calc(12px + 2vmin);
  text-decoration: underline;
}

.sub-section{
  margin-bottom: 36px;
}

.sub-header{
  font-size: calc(4px + 2vmin);
}

.App-link {
  color: #61dafb;
}